<template>
    <vs-row class="h-100 w-100 d-flex align-items-center justify-content-center bg-img">
        <vs-col vs-xs="12" vs-sm="12" vs-lg="7" class="pl-2 pr-2">
            <vs-card class="table-responsive">
                <div>
                    <vs-row>
                        <vs-col class="full-page-bg-color" vs-type="flex" vs-xs="0" vs-sm="6" vs-lg="6">
                            <img src="@/assets/images/pages/login.png" alt="login" class="m-auto w-100">
                        </vs-col>
                        <vs-col vs-xs="12" vs-sm="6" vs-lg="6">
                            <router-view></router-view>
                        </vs-col>
                    </vs-row>
                </div>
            </vs-card>
        </vs-col>
    </vs-row>
</template>

<script>
export default {
    name: 'Login'
}
</script>